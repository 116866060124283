
.ant-tooltip-inner {
    color: yellow !important;
    background-color: green;
}
#custom-popover {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    min-width: 200px;
  }